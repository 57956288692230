(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('UserCtrl', UserCtrl);

    function UserCtrl($scope, $state, $stateParams, $location, $http, AuthenticationService, _env) {

        if ($stateParams.id) {
            $http.get(_env.apiUrl + '/users/' + $stateParams.id).then(function (response) {
                $scope.user = response.data;
            });
        } else {
            $scope.user = {};
        }

        $scope.save = function () {

            if (!$scope.user) {
                return;
            }

            if (!$scope.user.name || !$scope.user.name.trim()) {
                return;
            }

            $scope.processing = true;
            if ($scope.user.id) {
                $http.put(_env.apiUrl + '/users/' + $scope.user.id, $scope.user).then(function(response) {
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Action successfully completed',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(function () {
                        history.back();
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/users', $scope.user).then(function(response) {
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Action successfully completed',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(function () {
                        history.back();
                    });
                });
            }
        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $http.delete(_env.apiUrl + '/users/' + $scope.user.id).then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Action successfully completed',
                            showConfirmButton: false,
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    }, function (response) {
                        if (response && response.data) {
                            Swal.fire({
                                position: 'top-end',
                                type: 'error',
                                title: response.data,
                                showConfirmButton: true
                            });
                        }
                    });
                }
            });
        }
    }
})();