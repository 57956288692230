(function () {
    'use strict';
    
    
    var env = {};
    if(window){  
        Object.assign(env, window._env);
    }
    
    angular
        .module('accesses.bimiboo.com', [
            'ui.router', 'ngSanitize', 'templates', 'bw.paging', 'schemaForm', 'ui.ace', 'ui.sortable'
        ])
        .constant('_env', env)
        .config(httpProviderConfig)
        .config(routeProviderConfig)
        .run(run);
    
    function httpProviderConfig($httpProvider) {
        $httpProvider.interceptors.push(function($q) {
            return {
                'responseError': function(rejection) {
                    var defer = $q.defer();
                    if(rejection.status == 401) {
                        window.location.assign('#/login');
                    }
                    defer.reject(rejection);

                    return defer.promise;
                },
                'request': function(config) {
                    config.headers = config.headers || {};
                    if (localStorage.getItem('token')) {
                        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
                    }
                    return config || $q.when(config);
                }
            };
        });
    }

    function routeProviderConfig($stateProvider, $urlRouterProvider) {
        
        $urlRouterProvider
            .when('', '/services')
            .otherwise('/services');

        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'templates/login/view.html',
                controller: 'LoginCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: true
                }
            })
            .state('home', {
                url: '',
                templateUrl: 'templates/home/view.html',
                controller: 'HomeCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.users', {
                url: '/users',
                templateUrl: 'templates/users/list/view.html',
                controller: 'UsersCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.user', {
                url: '/user/:id',
                templateUrl: 'templates/users/edit/view.html',
                controller: 'UserCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.services', {
                url: '/services',
                templateUrl: 'templates/services/list/view.html',
                controller: 'ServicesCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.service', {
                url: '/service/:id',
                templateUrl: 'templates/services/edit/view.html',
                controller: 'ServiceCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.servers', {
                url: '/servers',
                templateUrl: 'templates/servers/list/view.html',
                controller: 'ServersCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.server', {
                url: '/server/:id',
                templateUrl: 'templates/servers/edit/view.html',
                controller: 'ServerCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.licenses', {
                url: '/licenses',
                templateUrl: 'templates/licenses/list/view.html',
                controller: 'LicensesCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.license', {
                url: '/license/:id',
                templateUrl: 'templates/licenses/edit/view.html',
                controller: 'LicenseCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.emails', {
                url: '/emails',
                templateUrl: 'templates/emails/list/view.html',
                controller: 'EmailsCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.email', {
                url: '/email/:id',
                templateUrl: 'templates/emails/edit/view.html',
                controller: 'EmailCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.tokens', {
                url: '/tokens',
                templateUrl: 'templates/tokens/list/view.html',
                controller: 'TokensCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            })
            .state('home.token', {
                url: '/token/:id',
                templateUrl: 'templates/tokens/edit/view.html',
                controller: 'TokenCtrl',
                restrictions: {
                    ensureAuthenticated: false,
                    loginRedirect: false
                }
            });

    }

    function run($rootScope, $timeout) {

        $rootScope.formatDate = formatDate;

        $rootScope.$on('$routeChangeStart', function(event, next, current) {

            if (next.restrictions.ensureAuthenticated) {
                if (!localStorage.getItem('token')) {
                    $location.path('/login');
                }
            }

            if (next.restrictions.loginRedirect) {
                if (localStorage.getItem('token')) {
                    $location.path('/home');
                }
            }

        });

        $rootScope.$on('$stateChangeSuccess', function () {
            $timeout(function () {
                // fix sidebar
                var neg = $('.main-header').outerHeight() + $('.main-footer').outerHeight()
                var window_height = $(window).height()
                var sidebar_height = $('.sidebar').height()

                if ($('body').hasClass('fixed')) {
                    $('.content-wrapper, .right-side').css('min-height', window_height - $('.main-footer').outerHeight())
                } else {
                    if (window_height >= sidebar_height) {
                        $('.content-wrapper, .right-side').css('min-height', window_height - neg)
                    } else {
                        $('.content-wrapper, .right-side').css('min-height', sidebar_height)
                    }
                }
            })
        })
    }
    
})();
