(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('ServiceCtrl', ServiceCtrl);

    function ServiceCtrl($scope, $state, $stateParams, $location, $http, AuthenticationService, _env) {

        $http.get(_env.apiUrl + '/users').then(function (response) {
            $scope.users = response.data;
        });

        $scope.getUserById = function(id) {
            if ($scope.users) {
                var user = _.find($scope.users, function (user) {
                    return user.id == id;
                });

                if (user) {
                    return user.emails;
                }
            }

            return null;
        }

        $scope.service = {};
        function getService() {
            var id = $stateParams.id || $scope.service.id;
            if (id) {
                $http.get(_env.apiUrl + '/services/' + id).then(function (response) {
                    $scope.service = response.data;
                });
            }
        }
        getService();

        $scope.save = function () {

            if (!$scope.service) {
                return;
            }

            if (!$scope.service.title || !$scope.service.title.trim()) {
                return;
            }

            $scope.processing = true;
            if ($scope.service.id) {
                $http.put(_env.apiUrl + '/services/' + $scope.service.id, $scope.service).then(function(response) {
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Action successfully completed',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(function () {
                        history.back();
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/services', $scope.service).then(function(response) {
                    $scope.service.id = response.data.id;
                });
            }
        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $http.delete(_env.apiUrl + '/services/' + $scope.service.id).then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Action successfully completed',
                            showConfirmButton: false,
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    });
                }
            });
        }

        $scope.showAddRoleForm = function(role) {

            if (role) {
                $scope.role = angular.copy(role);
            } else {
                $scope.role = {
                    service_id: $scope.service.id
                };
            }

            $('#modal-default').modal('show');
        }

        $scope.submitAddRoleForm = function () {
            if (!$scope.role) return;

            if (!$scope.role.title) {
                error('Title not selected');
                return;
            }

            if (!$scope.role.description) {
                error('Description not specified');
                return;
            }

            $scope.proccessing = true;

            if ($scope.role.id) {
                $http.put(_env.apiUrl + '/roles/' + $scope.role.id, $scope.role).then(function() {
                    $('#modal-default').modal('hide');
                    $scope.proccessing = false;
                    getService();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/roles', $scope.role).then(function() {
                    $('#modal-default').modal('hide');
                    $scope.proccessing = false;
                    getService();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.deleteRole = function (role) {
            if (!role || !role.id) return;

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/roles/' + role.id).then(function () {
                        getService();
                        $scope.proccessing = false;
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        }

        $scope.showAttachUserForm = function(serviceUser) {
            if (serviceUser) {
                $scope.serviceUser = angular.copy(serviceUser);
            } else {
                $scope.serviceUser = {};
            }

            $('#modal-service-user').modal('show');
        }

        $scope.submitAttachUserForm = function () {
            if (!$scope.serviceUser) return;

            if (!$scope.serviceUser.user_id) {
                error('User not selected');
                return;
            }

            if (!$scope.serviceUser.role_id) {
                error('Description not specified');
                return;
            }

            var data = {
                role_id: $scope.serviceUser.role_id,
                user_id: $scope.serviceUser.user_id,
                email_id: $scope.serviceUser.email_id
            };

            $scope.proccessing = true;
            if ($scope.serviceUser.id) {
                $http.put(_env.apiUrl + '/services/users/' + $scope.serviceUser.id, data).then(function() {
                    $('#modal-service-user').modal('hide');
                    $scope.proccessing = false;
                    getService();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/services/users', data).then(function() {
                    $('#modal-service-user').modal('hide');
                    $scope.proccessing = false;
                    getService();
                }, function (reason) {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: JSON.stringify(reason.data, null, 2)
                    });
                });
            }
        }

        $scope.getUserMails = function() {
            if (!$scope.attachment) return [];
            if (!$scope.attachment.userId) return [];

            return _.find($scope.users, function (user) {
                return user.id == $scope.attachment.userId;
            }).emails;
        }
        
        $scope.detachUserFromRole = function (user) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $scope.proccessing = true;
                    $http.delete(_env.apiUrl + '/services/users/' + user.id).then(function() {
                        $scope.proccessing = false;
                        getService();
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
            
        }
    }
})();