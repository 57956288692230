function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function formatDate(dateStr, format) {
    if (!dateStr) return '';
    var date = moment.utc(dateStr).utcOffset(0).local();
    if (format) {
        return date.format(format)
    }

    return date.fromNow();
}

function isSameDay(m1, m2){
    return m1.year() === m2.year() && m1.month() === m2.month() && m1.date() === m2.date();
}

function getFileType(name) {
    var fileTypes = {
        compressed: ['zip', 'rar', 'gz'],
        css: ['css'],
        developer: ['c', 'cpp', 'h', 'js', 'json', 'm', 'mm', 'php', 'sql', 'xml'],
        excel: ['xls', 'xlsx', 'csv'],
        fireworks: ['fw'],
        flash: ['fs', 'fla', 'swf'],
        html: ['htm', 'html'],
        illustrator: ['ai'],
        image: ['bmp', 'jpg', 'jpeg', 'gif', 'png', 'tiff'],
        movie: ['3gp', 'avi', 'mov', 'mp4', 'wmv'],
        music: ['mp3', 'wav', 'ogg'],
        numbers: ['numbers'],
        pages: ['pages'],
        pdf: ['pdf'],
        photoshop: ['psd'],
        powerpoint: ['ppt', 'pptx'],
        text: ['txt', 'rtf', 'in'],
        word: ['doc', 'docx']
    };

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(name)[1];
    var type = 'unknown';

    if (ext != undefined) {
        for (var index in fileTypes) {
            if (fileTypes[index].includes(ext)) {
                type = index;
                break;
            }
        }
    }

    return type;
}

function dateRange(startDate, endDate) {
    var dates = [];
    var currentDate = new Date(startDate);
    var addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    while (currentDate <= new Date(endDate)) {
        dates.push(moment(currentDate));
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
}

function move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
}

function error(message) {
    Swal.fire({
        type: 'error',
        title: message,
        position: 'top-end',
        timer: 2000
    });
}

function getMonth() {
    var month = localStorage.getItem('month');
    if (!month) {
        month = moment().add(1, 'M').startOf('month').format('MMMM, YYYY');
        setMonth(month);
    }

    return month;
}

function setMonth(month) {
    localStorage.setItem('month', month);
}