(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('UsersCtrl', UsersCtrl);

    function UsersCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getUsers() {
            $http.get(_env.apiUrl + '/users').then(function (response) {
                $scope.users = _.map(response.data, function (user) {
                    user.roles = [];
                    _.forEach(user.emails, function (email) {
                        _.forEach(email.roles, function (role) {
                            var role = role;
                            role.email = email;
                            user.roles.push(role);
                        });
                    });
                    return user;
                });
            });
        }
        getUsers();

        $scope.showUserForm = function () {
            $scope.user = {};
            $('#modal-default').modal('show');
        }

        $scope.submitUserForm = function () {

            if (!$scope.user.name || !$scope.user.name.trim()) {
                error('Name not specified');
                return;
            }

            $scope.processing = true;
            $http.post(_env.apiUrl + '/users', $scope.user).then(function(response) {
                getUsers();
                $('#modal-default').modal('hide');
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Action successfully completed',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }
    }
})();