(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('ServicesCtrl', ServicesCtrl);

    function ServicesCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getServices() {
            $http.get(_env.apiUrl + '/services').then(function (response) {
                $scope.services = response.data;
            });
        }

        $http.get(_env.apiUrl + '/users').then(function (response) {
            $scope.users = response.data;
            getServices();
        });

        $scope.getUserById = function(id) {
            if ($scope.users) {
                var user = _.find($scope.users, function (user) {
                    return user.id == id;
                });

                if (user) {
                    return user.emails;
                }
            }

            return null;
        }

        $scope.showServiceForm = function () {
            $scope.service = {};

            $('#modal-default').modal('show');

        }

        $scope.submitServiceForm = function () {

            if (!$scope.service.title || !$scope.service.title.trim()) {
                error('Title not specified');
                return;
            }

            if (!$scope.service.user_id) {
                error('Owner not specified');
                return;
            }

            var data = {
                'title': $scope.service.title,
                'url': $scope.service.url,
                'description': $scope.service.description,
                'user_id': $scope.service.user_id,
                'email_id': $scope.service.email_id
            }

            $scope.processing = true;
            $http.post(_env.apiUrl + '/services', data).then(function(response) {
                $('#modal-default').modal('hide');
                getServices();
                Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Action successfully completed',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
        }
    }
})();