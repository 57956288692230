(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('ServerCtrl', ServerCtrl);

    function ServerCtrl($scope, $state, $stateParams, $location, $http, AuthenticationService, _env) {

        $http.get(_env.apiUrl + '/users').then(function (response) {
            $scope.users = response.data;
        });

        if ($stateParams.id) {
            $http.get(_env.apiUrl + '/servers/' + $stateParams.id).then(function (response) {
                $scope.server = response.data;
                $scope.server.users = _.map($scope.server.users, function (user) {
                    user.hostUser = user.pivot.host_user;
                    return user;
                });
            });
        } else {
            $scope.server = {};
        }

        $scope.deleteUser = function (deletedUser) {
            $scope.server.users = _.filter($scope.server.users, function (user) {
                return user.id != deletedUser.id;
            });
        }

        $scope.$watch("server.newUserId", function (newValue, oldValue) {
            if (newValue != oldValue) {
                if ($scope.server.newUserId) {
                    var user = _.find($scope.users, function (user) {
                        return user.id == $scope.server.newUserId;
                    });

                    if (user) {
                        $scope.server.users.push(user);
                    }

                    $scope.server.newUserId = null;
                }
            }
        });

        $scope.save = function () {

            if (!$scope.server) {
                return;
            }

            if (!$scope.server.title || !$scope.server.title.trim()) {
                return;
            }

            if (!$scope.server.ip || !$scope.server.ip.trim()) {
                return;
            }

            if (!$scope.server.description || !$scope.server.description.trim()) {
                return;
            }

            var item = angular.copy($scope.server);
            item.users = _.map(item.users, function (user) {
                return {
                    user_id: user.id,
                    host_user: user.hostUser
                };
            });
            if (_.find(item.users, function (user) {
                return !user.host_user;
            })) {
                return;
            }

            $scope.processing = true;
            if ($scope.server.id) {
                $http.put(_env.apiUrl + '/servers/' + $scope.server.id, item).then(function(response) {
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Action successfully completed',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(function () {
                        history.back();
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/servers', item).then(function(response) {
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Action successfully completed',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(function () {
                        history.back();
                    });
                });
            }
        }

        $scope.delete = function () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $http.delete(_env.apiUrl + '/servers/' + $scope.server.id).then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Action successfully completed',
                            showConfirmButton: false,
                            timer: 1000
                        }).then(function () {
                            history.back();
                        });
                    });
                }
            });
        }
    }
})();