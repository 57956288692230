(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('LicensesCtrl', LicensesCtrl);

    function LicensesCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        $http.get(_env.apiUrl + '/licenses').then(function (response) {
            $scope.licenses = response.data;
        });
    }
})();