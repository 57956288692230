(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('TokensCtrl', TokensCtrl);

    function TokensCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        $http.get(_env.apiUrl + '/tokens').then(function (response) {
            $scope.apiAccesses = response.data;
        });
    }
})();