(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .directive('loading', loading);

    function loading($http) {
        return {
            restrict: 'A',
            link: function (scope, elm)
            {
                scope.isLoading = function () {
                    return $http.pendingRequests.length > 0;
                };

                scope.$watch(scope.isLoading, function (v)
                {
                    if(v){
                        elm.show();
                    }else{
                        elm.hide();
                    }
                });
            }
        };
    }

})();