(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('HomeCtrl', HomeCtrl);

    function HomeCtrl($scope, $location, $http, AuthenticationService, _env) {

        $scope.auth = AuthenticationService;

        AuthenticationService.refresh(function () {
            $scope.user = AuthenticationService.user;
            $('[data-widget="tree"]').tree();
        });

        $scope.logout = function () {
            AuthenticationService.Logout();
        };

        $scope.showChangePasswordForm = function () {
            Swal.fire({
                title: 'Change password',
                input: 'password',
                inputPlaceholder: 'Type new password here',
                inputValidator: function (value) {
                    if (!value) {
                        return 'You need to write some password!'
                    }
                },
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                reverseButtons: true
            }).then(function(result) {
                if (result.value && result.value.trim()) {
                    var data = {
                        'password': result.value.trim()
                    };

                    $http.put(_env.apiUrl + '/users/change_password', data).then(function(response) {
                        Swal.fire({
                            type: 'success',
                            title: 'Password successfully saved!'
                        });
                    }, function (reason) {
                        Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: JSON.stringify(reason.data, null, 2)
                        });
                    });
                }
            });
        };



        $scope.getCurrentYear = function() {
            return new Date().getFullYear();
        }
    }
})();