(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .directive('datePicker', function () {
            return {
                restrict: 'A',
                require: '?ngModel',
                templateUrl: 'templates/directives/date-range-picker.html',
                link: function ($scope, element) {

                    element.datepicker({
                        format: 'mm/dd/yyyy',
                        autoclose: true,
                        startView: 'days'
                    }, function (start, end) {
                        $scope.dateFilter.callback();
                    });

                    $('.input-group-addon', element.parent()).on('click', function () {
                        element.focus();
                    })
                }
            };
        })
        .directive('yearPicker', function () {
            return {
                restrict: 'A',
                require: '?ngModel',
                templateUrl: 'templates/directives/date-range-picker.html',
                link: function ($scope, element) {

                    element.datepicker({
                        autoclose: true,
                        startView: 'decade',
                        format: "yyyy",
                        viewMode: "years",
                        minViewMode: "years"
                    }, function (start, end) {
                        $scope.dateFilter.callback();
                    });

                    $('.input-group-addon', element.parent()).on('click', function () {
                        element.focus();
                    })
                }
            };
        })
        .directive('monthPicker', function () {
            return {
                restrict: 'A',
                require: '?ngModel',
                templateUrl: 'templates/directives/date-range-picker.html',
                link: function ($scope, element) {

                    element.datepicker({
                        autoclose: true,
                        startView: 'months',
                        format: 'MM, yyyy',
                        viewMode: "months",
                        minViewMode: "months"
                    }, function (start, end) {
                        $scope.dateFilter.callback();
                    });

                    $('.input-group-addon', element.parent()).on('click', function () {
                        element.focus();
                    })
                }
            };
        });

})();