(function () {
    'use strict';

    angular
        .module('accesses.bimiboo.com')
        .controller('EmailsCtrl', EmailsCtrl);

    function EmailsCtrl($scope, $state, $location, $http, AuthenticationService, _env) {

        function getEmails() {
            $http.get(_env.apiUrl + '/emails').then(function (response) {
                $scope.emails = response.data;
            });
        }
        getEmails();

        $http.get(_env.apiUrl + '/users').then(function (response) {
            $scope.users = response.data;
        });

        $scope.showEmailForm = function (email) {
            if (email) {
                $scope.email = angular.copy(email);
            } else {
                $scope.email = {};
            }

            $('#modal-default').modal('show');

        }

        $scope.submitEmailForm = function () {

            if (!$scope.email.email || !$scope.email.email.trim()) {
                error('Email not specified');
                return;
            }

            if (!$scope.email.user_id) {
                error('Owner not selected');
                return;
            }

            $scope.processing = true;
            if ($scope.email.id) {
                $http.put(_env.apiUrl + '/emails/' + $scope.email.id, $scope.email).then(function(response) {
                    $('#modal-default').modal('hide');
                    getEmails();
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Action successfully completed',
                        showConfirmButton: false,
                        timer: 1000
                    });
                });
            } else {
                $http.post(_env.apiUrl + '/emails', $scope.email).then(function(response) {
                    $('#modal-default').modal('hide');
                    getEmails();
                    Swal.fire({
                        position: 'top-end',
                        type: 'success',
                        title: 'Action successfully completed',
                        showConfirmButton: false,
                        timer: 1000
                    });
                });
            }
        }

        $scope.delete = function (email) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    $http.delete(_env.apiUrl + '/emails/' + email.id).then(function (response) {
                        getEmails();
                        Swal.fire({
                            position: 'top-end',
                            type: 'success',
                            title: 'Action successfully completed',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    });
                }
            });
        }
    }
})();